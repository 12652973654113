body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #333;
  padding-top: 60px;
  font-size: 12px; }

* {
  outline: none !important;
  -moz-outline: none !important; }

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important; }

.container-fluid {
  padding-left: 8px;
  padding-right: 8px; }

.row {
  margin-left: -8px;
  margin-right: -8px; }

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  padding-left: 8px;
  padding-right: 8px; }

.development {
  position: absolute;
  width: 149px;
  height: 149px;
  top: 0;
  left: 0;
  border: 0;
  z-index: 1000;
  background-image: url("../images/development_ribbon.png");
  background-position: center center;
  background-repeat: no-repeat;
  pointer-events: none; }

.navbar-version {
  font-size: 10px;
  color: #ccc;
  line-height: 40px; }

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

.hero-unit {
  margin: 50px auto 0 auto;
  width: 300px;
  font-size: 18px;
  font-weight: 200;
  line-height: 30px;
  background-color: #eee;
  border-radius: 6px;
  padding: 60px; }
  .hero-unit h1 {
    font-size: 60px;
    line-height: 1;
    letter-spacing: -1px; }

.error {
  color: white;
  background-color: red; }

.hipster {
  display: inline-block;
  width: 347px;
  height: 497px;
  background: url("../images/hipster.png") no-repeat center top;
  background-size: contain; }

.pad {
  padding: 10px; }

.break {
  white-space: normal;
  word-break: break-all; }

/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .hipster {
    background: url("../images/hipster2x.png") no-repeat center top;
    background-size: contain; } }

/* make sure browsers use the pointer cursor for anchors, even with no href */
a:hover {
  cursor: pointer; }

.hand {
  cursor: pointer; }

#threadDump .popover, #healthCheck .popover {
  top: inherit;
  display: block;
  font-size: 10px;
  max-width: 1024px; }

#healthCheck .popover {
  margin-left: -50px; }

.health-details {
  min-width: 400px; }

.voffset {
  margin-top: 2px; }

.voffset1 {
  margin-top: 5px; }

.voffset2 {
  margin-top: 10px; }

.voffset3 {
  margin-top: 15px; }

.voffset4 {
  margin-top: 30px; }

.voffset5 {
  margin-top: 40px; }

.voffset6 {
  margin-top: 60px; }

.voffset7 {
  margin-top: 80px; }

.voffset8 {
  margin-top: 100px; }

.voffset9 {
  margin-top: 150px; }

/* start Password strength bar style */
ul#strength {
  display: inline;
  list-style: none;
  margin: 0;
  margin-left: 15px;
  padding: 0;
  vertical-align: 2px; }

.point {
  background: #DDD;
  border-radius: 2px;
  display: inline-block;
  height: 5px;
  margin-right: 1px;
  width: 20px; }
  .point:last {
    margin: 0 !important; }

/* end Password strength bar style */
/* Custom alerts for notification */
.alerts .alert {
  text-overflow: ellipsis; }
  .alerts .alert pre {
    background: none;
    border: none;
    font: inherit;
    color: inherit;
    padding: 0;
    margin: 0; }
  .alerts .alert .popover pre {
    font-size: 10px; }

.alerts .toast {
  position: fixed; }
  .alerts .toast.left {
    left: 5px; }
  .alerts .toast.right {
    right: 5px; }
  .alerts .toast.top {
    top: 55px; }
  .alerts .toast.bottom {
    bottom: 55px; }

@media screen and (min-width: 960px) {
  .alerts .toast {
    width: 50%; } }

@media screen and (max-width: 959px) {
  .alerts .toast {
    width: 100%;
    left: 5px;
    right: 5px; } }

/* end of Custom alerts for notification */
/* entity tables helpers */
/* Remove Bootstrap padding from the element
   http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
.no-padding-left {
  padding-left: 0 !important; }

.no-padding-right {
  padding-right: 0 !important; }

.no-padding-top {
  padding-top: 0 !important; }

.no-padding-bottom {
  padding-bottom: 0 !important; }

.no-padding {
  padding: 0 !important; }

/* bootstrap 3 input-group 100% width
   http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min {
  width: 1% !important; }

/* Makes toolbar not wrap on smaller screens
   http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right */
.flex-btn-group-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end; }

.jh-table > tbody > tr > td {
  /* Align text in td verifically (top aligned by Bootstrap) */
  vertical-align: middle;
  padding: 5px; }

.jh-table > thead > tr > th > .glyphicon-sort, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt {
  /* less visible sorting icons */
  opacity: 0.5; }
  .jh-table > thead > tr > th > .glyphicon-sort:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt:hover {
    /* full visible sorting icons and pointer when mouse is over them */
    opacity: 1;
    cursor: pointer; }

/* end of entity tables helpers */
/* ui bootstrap tweaks */
.nav, .pagination, .carousel, .panel-title a {
  cursor: pointer; }

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-default,
.uib-datepicker-popup > li > div.uib-datepicker > table .btn-default {
  border: 0; }

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup > li > div.uib-datepicker > table:focus {
  outline: none; }

/* end of ui bootstrap tweaks */
tr.selected {
  background-color: #dff0d8 !important; }

@font-face {
  font-family: 'Care symbols';
  src: url("../fonts/caresym.ttf") format("truetype"); }

.jh-table > thead > tr > th {
  background-color: #f1f1f1; }

.tab-content {
  padding-top: 5px; }

.care-symbols {
  font-family: 'Care symbols'; }

input.care-symbols {
  height: auto;
  font-size: 40px; }

tr td.care-symbols {
  text-align: center;
  padding: 0 !important;
  font-size: 24px;
  line-height: 1 !important; }

.modal-body.small-padding .form-group {
  margin-bottom: 5px; }

.form-group {
  margin-bottom: 5px; }

p.product-code {
  color: #00AA00;
  font-weight: bolder; }

.modal .warning-info {
  font-weight: bold;
  color: #e87171; }

@media (min-width: 1200px) {
  .modal-lg {
    width: 1150px; } }

.form-group .input-group.textile-form {
  padding-left: 15px;
  padding-right: 15px; }

.textile-form .input-group-addon.textile {
  padding: 0px;
  background: none;
  border: none;
  width: 10%;
  min-width: 70px; }

.textile-form .input-group-addon.textile-padding {
  padding: 0px 10px; }

.alerts .toast {
  z-index: 3000; }

.toast-top-right {
  top: 55px; }

.footer {
  margin-top: 10px;
  margin-bottom: 10px; }

img.footer_logo {
  max-height: 17px;
  margin-top: -3px; }

.logo-dashboard {
  max-width: 250px;
  margin: 0 auto; }

@media (max-width: 767px) {
  .btn-mobile {
    width: 100%;
    margin: 8px 0;
    font-size: 16px;
    line-height: 1.33;
    border-radius: 6px;
    padding: 10px 16px; }
  h4, .h4, h5, .h5, h6, .h6 {
    margin-top: 5px;
    margin-bottom: 5px; }
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-left: 4px;
    padding-right: 4px; }
  .row {
    margin-left: -4px;
    margin-right: -4px; }
  .container-fluid {
    padding-left: 4px;
    padding-right: 4px; }
  .navbar-nav {
    margin: 5px -5px; } }

.modal-dialog {
  margin: 15px auto; }

.modal-body {
  padding: 10px; }

.modal-header {
  padding: 10px; }

.btn {
  font-size: 12px; }

.navbar-brand {
  font-size: 16px;
  padding: 4px 15px; }

.navbar-brand img {
  height: 42px;
  float: left; }

@media (min-width: 768px) {
  .navbar-version {
    line-height: 26px; }
  .navbar-right {
    margin-right: -8px; }
  .navbar {
    min-height: 36px;
    margin-bottom: 5px; }
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 16px; }
  .navbar-brand {
    padding: 3px 15px;
    font-size: 16px;
    line-height: 25px;
    height: 36px; }
  .navbar-brand img {
    height: 30px;
    float: left; }
  .table > thead > tr > th {
    vertical-align: middle; }
  body {
    font-size: 12px;
    padding-top: 45px; }
  .panel-default {
    margin-bottom: 6px; }
  .btn {
    font-size: 12px; }
  .dropdown-menu {
    font-size: 12px; }
    .dropdown-menu .divider {
      margin: 5px 0; }
  .form-inline .form-group {
    padding: 1px 0px; }
  .form-control {
    font-size: 12px; }
  h4, .h4 {
    font-size: 16px; }
  .table.table-condensed .input-sm, .table.table-condensed .input-group-sm > .form-control, .table.table-condensed .input-group-sm > .input-group-addon, .table.table-condensed .input-group-sm > .input-group-btn > .btn {
    height: 25px;
    padding: 3px 5px;
    line-height: 1; }
  .btn-group-sm .btn {
    font-size: 10.5px; }
  .btn-sm {
    font-size: 10.5px; }
  .modal-body {
    padding: 10px; }
  .modal-header {
    padding: 15px 10px; }
  h4 {
    font-size: 16px; } }

input.disabled-spinners::-webkit-outer-spin-button, input.disabled-spinners::-webkit-inner-spin-button {
  -webkit-appearance: none; }

input.disabled-spinners {
  -moz-appearance: textfield; }

.table-subtext {
  font-size: xx-small;
  color: #60615f; }

.form-quantity-input {
  width: 100%;
  min-width: 50px;
  text-align: center; }

.btn-options {
  color: #bccad8;
  background-color: transparent; }

td .btn-options {
  padding: 0px 10px;
  margin: 0; }

.btn-options:active, .btn-options.active {
  box-shadow: none;
  color: #bccad8; }

.text-bold {
  font-weight: bold; }

.table.table-condensed > tfoot.summary > tr > td {
  padding: 5px; }

tfoot.summary {
  background-color: #f1f1f1; }
  tfoot.summary .summary-text {
    font-size: larger; }
  tfoot.summary .summary-value {
    color: #0F6AB4;
    font-size: large; }

.table-bordered > tbody > tr.WARNING > td {
  background-color: #f7eded; }

.text-warning {
  font-weight: bold;
  color: red; }

.table > tbody > tr > td.padding-condensed {
  padding: 2px; }

.jh-table.table-cut-plans > thead > tr > th {
  background-color: rgba(167, 200, 226, 0.36); }

.jh-table.table-cutting > thead > tr > th {
  background-color: rgba(197, 230, 141, 0.48); }

.jh-table.table-orders > thead > tr > th {
  background-color: rgba(255, 245, 137, 0.48); }

.wrap-long-text {
  white-space: normal; }

span.product-orders {
  color: #eea236; }

span.product-cut-plans {
  color: blue; }

span.product-cutting {
  color: green; }

span.commissions {
  color: #a497ff; }

span.customers {
  color: #fd90fd; }

div.ng-dirty.ng-invalid > .select2-choice, input.ng-dirty.ng-invalid-number, input.ng-dirty.ng-invalid, select.ng-dirty.ng-invalid {
  border-color: #b94a48 !important; }

.jh-table th:focus {
  outline: none; }

.editable-click, a.editable-click {
  border-bottom: none;
  line-height: 1.42857; }

.small-padding {
  padding: 2px !important; }

.editable-wrap .editable-controls > input, .editable-wrap .editable-controls > select, .editable-wrap .editable-controls > textarea {
  padding: 3px; }

.popover-wrapper form {
  z-index: 1060; }

.table > tbody.no-border + tbody.no-border {
  border-top: none; }

tbody .editable-wrap, tbody .editable-wrap, tbody .editable-wrap .editable-controls, tbody .editable-wrap .editable-input {
  width: 100%; }

@media (max-width: 767px) {
  .navbar-nav li a, .navbar-nav .open .dropdown-menu > li > a {
    line-height: 26px; } }

.editable-textarea-notes {
  white-space: pre-wrap;
  line-height: 1.42857; }

.editable-textarea-notes:hover {
  text-decoration: none; }

a.editable-textarea-notes.editable-empty, a.editable-textarea-notes.editable-empty:hover, a.editable-textarea-notes.editable-empty:focus {
  color: gray; }

.btn-group-multiselect, .btn-group-multiselect.state-selected-multiple .dropdown-toggle .text, .btn-group-multiselect .dropdown-toggle {
  width: 100%; }

.label-role-ROLE_ADMIN {
  background-color: #7a43b6; }

.label-role-ROLE_OWNER {
  background-color: #335787; }

.label-role-ROLE_USER {
  background-color: #eea236; }

.daterangepicker.dropdown-menu {
  z-index: 1060; }

tr:focus {
  outline: none; }

tr.summary-partial {
  background-color: #F9F9F9; }

.table-responsive {
  overflow-y: unset;
  overflow-x: unset;
  margin-bottom: 10px; }

.bold {
  font-weight: bold; }

div.hr-line {
  border-bottom: 1px dotted #aebdc88f;
  margin: 10px 0 10px 0; }

div.form-group-label {
  margin-bottom: 0; }

.form-group.required label:after, .input-group.required label:after {
  content: "*";
  color: red;
  margin-left: 2px;
  margin-top: -4px;
  font-size: x-large;
  position: absolute; }

.label-commission-OPEN {
  background-color: #00AA00; }

.text-commission-OPEN {
  color: #00AA00; }

.label-commission-ISSUED {
  background-color: #d28642; }

.text-commission-ISSUED {
  color: #d28642; }

.label-commission-CLOSED {
  background-color: #1e347b; }

.text-commission-CLOSED {
  color: #1e347b; }

.label-commission-CANCELLED {
  background-color: #cc0000; }

.text-commission-CANCELLED {
  color: #cc0000; }

tr.table-sub-row > td {
  background-color: #ffffff; }

.btn.little-remove {
  font-size: 8px; }

span.pseudo-button {
  cursor: pointer; }

span.pseudo-button:focus {
  outline: none; }

.label-as-checkbox-switch {
  position: absolute;
  left: 0;
  font-size: small;
  cursor: pointer; }

ul.form-vertical {
  list-style: none;
  margin-bottom: 0;
  margin-top: 10px;
  -webkit-padding-start: 0;
  -khtml-padding-start: 0;
  -o-padding-start: 0;
  padding-start: 0; }

ul.form-vertical li {
  position: relative;
  padding: 0 0 10px 42px; }

ul.form-vertical li label {
  cursor: pointer;
  margin-top: 2px; }

.label-as-checkbox-text {
  padding-left: 10px; }

span.field-true {
  color: #5cb85c; }

span.field-false {
  color: #f0ad4e; }

span.delete-button-color {
  color: #cc0000; }

.well-commission {
  padding: 5px;
  margin-bottom: 5px; }

dl.no-margin {
  margin-bottom: 0; }

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
  color: #fff;
  background-color: #29d; }

div.form-group > ul.checkbox-like-list {
  margin-top: 20px; }

.settlement-color {
  color: #ca9734; }

.invalid {
  color: #333;
  background-color: #ff000026; }

.error-text {
  color: #cc0000; }

.warning-text {
  color: #ca9734; }

.form-horizontal > .modal-body .form-group {
  margin-left: 0;
  margin-right: 0; }

.panel-description {
  margin-bottom: 5px; }

.panel-description .panel-key {
  font-size: smaller;
  margin-right: 5px; }

.panel-description .panel-value {
  color: #9c5151;
  font-weight: bold;
  font-size: larger; }

h5.details-header {
  font-size: 16px;
  font-weight: bold; }

.atomic-change-ADDED {
  color: darkgreen; }

.atomic-change-UPDATED {
  color: #00AA00; }

.atomic-change-DELETED {
  color: #d28642; }

.atomic-change-ERROR {
  color: #cc0000; }

.fc-toolbar h2 {
  font-size: 1em;
  padding: 5px; }

@media (max-width: 767px) {
  .fc-toolbar h2 {
    font-size: medium; } }

.dashboard-widget {
  margin-bottom: 20px; }

.input-group-sm label, .input-group label, .form-group label {
  margin-bottom: 3px; }

input[type="radio"], input[type="checkbox"] {
  margin: 4px 8px 0 0; }

.radio, .checkbox {
  margin-bottom: 5px; }

.label-larger {
  font-size: 100%; }

.calendar-reservation-status-UNCONFIRMED .fc-bg,
.calendar-reservation-status-UNCONFIRMED.fc-event,
.calendar-reservation-status-UNCONFIRMED .fc-event-dot,
.label-entry-status-UNCONFIRMED {
  background-color: #337ab7;
  border-color: #1e496d; }

.calendar-reservation-status-CONFIRMED .fc-bg,
.calendar-reservation-status-CONFIRMED.fc-event,
.calendar-reservation-status-CONFIRMED .fc-event-dot,
.label-entry-status-CONFIRMED {
  background-color: #5cad3a;
  border-color: #366823; }

.calendar-reservation-status-CANCELLED .fc-bg,
.calendar-reservation-status-CANCELLED.fc-event,
.calendar-reservation-status-CANCELLED .fc-event-dot,
.label-entry-status-CANCELLED {
  background-color: #d28642;
  border-color: #774a26; }

.calendar-reservation-status-PROCESSED .fc-bg,
.calendar-reservation-status-PROCESSED.fc-event,
.calendar-reservation-status-PROCESSED .fc-event-dot,
.label-entry-status-PROCESSED {
  background-color: darkgreen;
  border-color: #003900; }

.calendar-reservation-status-UNPROCESSED_DID_NOT_ARRIVE .fc-bg,
.calendar-reservation-status-UNPROCESSED_DID_NOT_ARRIVE.fc-event,
.calendar-reservation-status-UNPROCESSED_DID_NOT_ARRIVE .fc-event-dot,
.label-entry-status-UNPROCESSED_DID_NOT_ARRIVE {
  background-color: #cc0000;
  border-color: #990000; }

.calendar-reservation-status-PROCESSED_DELAYED .fc-bg,
.calendar-reservation-status-PROCESSED_DELAYED.fc-event,
.calendar-reservation-status-PROCESSED_DELAYED .fc-event-dot,
.label-entry-status-PROCESSED_DELAYED {
  background-color: #777777;
  border-color: #474747; }

.fc-event .fc-bg {
  opacity: 1; }

.item-detail-label {
  width: 40px;
  float: left;
  clear: both; }

.item-detail-value {
  margin-left: 40px;
  margin-bottom: 8px; }

.item-detail-value:after {
  clear: both; }

.no-data {
  font-style: italic; }

ul.dropdown-menu li a i {
  margin-right: 6px; }

.btn-spinner:not(:required) {
  height: 14px;
  width: 14px; }

.panel-no-comments {
  text-align: center;
  padding: 10px; }

.panel-loading-spinner {
  text-align: center; }

.panel-body-sm {
  padding: 0 5px; }

.panel-gray {
  background: none repeat scroll 0 0 #fafafa; }

@media (min-width: 768px) {
  .bordered-sm-right {
    border-right: 1px dotted #aebdc8; } }

textarea#entry-comment-input {
  height: 100px; }

.new-comment-header {
  margin-bottom: 5px; }

.user-thumb {
  float: left;
  height: 40px;
  width: 40px;
  padding: 3px;
  margin-right: 5px; }

.user-thumb + .resource {
  display: table-cell;
  padding-bottom: 5px; }

.comment-row {
  padding: 3px; }

.info-block {
  margin-bottom: 3px; }

.author-info {
  font-weight: bold;
  margin-right: 10px; }

.time-info {
  font-style: italic;
  font-size: smaller; }

div.bordered-resource:not(:last-child) {
  border-bottom: 1px solid #ddd; }

div.bordered-resource:not(:first-child):before {
  margin-bottom: 10px; }

div.bordered-resource:not(:first-child):after {
  margin-top: 10px; }

.calendar-reserved-time {
  background: #adacac;
  border-color: #adacac; }

.bootstrap-datetimepicker-widget a[data-action] {
  text-align: center; }

div.fc-agendaDay-view td.fc-today {
  background-color: transparent; }

.filter-radio-button {
  float: right; }

input.date-range-picker {
  min-width: 160px; }

.trash-clear {
  color: indianred; }

.input-group ol.nya-bs-select {
  width: 100%; }

.nya-bs-select .dropdown-menu li.nya-bs-option.disabled {
  background-color: #eeeeee; }

.nya-bs-select .dropdown-menu li a {
  padding: 5px 20px; }

.nya-bs-select.btn-group .dropdown-menu li.selected a {
  color: #fff;
  text-decoration: none;
  background-color: #428bca;
  outline: 0; }
  .nya-bs-select.btn-group .dropdown-menu li.selected a .check-mark {
    margin-top: 2px;
    right: 10px; }

.nya-bs-select .dropdown-toggle.show-special-title .special-title {
  color: #999; }

.nya-bs-select .dropdown-toggle .filter-option {
  padding-right: 15px; }

.nya-bs-select .dropdown-toggle .special-title {
  padding-right: 15px; }

.nya-bs-select .dropdown-toggle .caret {
  color: #999; }

.nya-bs-select .bs-actionsbox {
  min-width: 220px; }
  .nya-bs-select .bs-actionsbox .btn {
    padding: 2px 4px;
    font-size: smaller;
    text-align: center; }

.select-table-filters {
  min-width: 150px; }

.table-no-margin {
  margin-bottom: 0; }

.table-loading-spinner {
  text-align: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-top: 0; }
  @media screen and (max-width: 767px) {
    .table-loading-spinner {
      border: 0;
      border-top: 1px solid #ddd; } }

.state-loading-spinner {
  text-align: center;
  padding: 10px;
  border: 0; }

.table-no-data {
  text-align: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-top: 0; }
  @media screen and (max-width: 767px) {
    .table-no-data {
      border: 0;
      border-top: 1px solid #ddd; } }

.table-bordered > thead > tr > th, .table-bordered > thead > tr > td {
  border-bottom: 0; }

.jh-table thead tr th span:not(:last-child) {
  margin-right: 5px; }

.btn-sm, .btn-group-sm > .btn {
  font-size: 10px; }

.list-group-item.selected, .list-group-item.selected:hover, .list-group-item.selected:focus {
  z-index: 2;
  background-color: #eeeeee;
  color: #777777; }

.panel-heading.widget-heading {
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 27px; }
  .panel-heading.widget-heading .widget-title {
    float: left !important;
    font-weight: bold; }
  .panel-heading.widget-heading .widget-buttons {
    float: right !important;
    margin-right: -10px; }

.search-panel {
  padding: 5px; }

.modal-footer .btn-group + .btn-group {
  margin-left: 5px; }

.panel .form-group {
  margin-left: 1px;
  margin-right: 1px; }

.daterangepicker .ranges li {
  font-size: 12px;
  padding: 2px 9px;
  /* margin-bottom: 8px; */ }

.fc-time-grid .fc-content-skeleton {
  z-index: auto; }

.calendar-reserved-time {
  background: #eee;
  border-color: #eee; }

.fc-bgevent {
  opacity: 1; }

.fc-time-grid .fc-slats, .fc-time-grid > hr {
  z-index: 3; }

.work-stand-by-true {
  color: #5cad3a; }

.work-stand-by-false {
  color: indianred; }

.fast-entry-content {
  white-space: pre-wrap; }

.fast-entry-toggle {
  position: absolute;
  left: 10px;
  top: 10px; }

.fast-entry-actions {
  position: absolute;
  right: 0;
  top: 5px; }

div.fast-entry-view {
  position: relative; }
  div.fast-entry-view.editing label {
    display: none; }
  div.fast-entry-view.editing form {
    display: block; }
  div.fast-entry-view label {
    word-break: break-all;
    padding: 10px 25px 10px 35px;
    display: block;
    line-height: 1.6;
    transition: color 0.4s;
    margin-bottom: 0; }
    div.fast-entry-view label.done {
      text-decoration: line-through; }
  div.fast-entry-view form {
    display: none; }

ul.fast-entry-filters {
  padding: 10px 0;
  list-style: none;
  position: relative;
  text-align: center;
  margin: 0; }
  ul.fast-entry-filters li {
    display: inline; }
    ul.fast-entry-filters li a {
      color: inherit;
      margin: 3px;
      padding: 3px 7px;
      text-decoration: none;
      border: 1px solid transparent;
      border-radius: 3px; }
      ul.fast-entry-filters li a.selected {
        border-color: rgba(175, 47, 47, 0.2); }

.new-fast-entry, .edit-fast-entry {
  position: relative;
  margin: 0;
  width: 100%;
  font-size: 12px;
  font-family: inherit;
  font-weight: inherit;
  line-height: 1.6em;
  border: 0;
  color: inherit;
  padding: 6px;
  border: 1px solid #999;
  box-shadow: inset 0 -1px 5px 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.new-fast-entry {
  padding: 10px 25px 10px 35px;
  border: none;
  background: rgba(0, 0, 0, 0.003);
  box-shadow: none; }

.panel-heading .timezone {
  font-size: x-small; }

@media screen and (max-width: 959px) {
  ul.nav li a i {
    margin-right: 10px; } }

.navbar-fixed-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
  max-height: none; }

body.tv-mode {
  padding-top: 10px;
  font-size: xx-large;
  background-color: black;
  color: #f1f1f1; }
  body.tv-mode .panel-default {
    border-color: black;
    color: #f1f1f1; }
  body.tv-mode .footer {
    position: fixed;
    bottom: 0;
    font-size: large; }
  body.tv-mode .fc-unthemed .fc-list-empty {
    background-color: unset;
    padding-top: 10px; }
  body.tv-mode .fc-unthemed .fc-list-view {
    border-color: unset;
    border-width: 1px 0 0; }
  body.tv-mode .fc-unthemed .fc-button-group {
    display: none; }
  body.tv-mode .fc-unthemed .fc-list-heading td {
    background: transparent; }
  body.tv-mode .fc-unthemed tr.fc-list-item:hover td {
    background: black !important; }
  body.tv-mode .modal-content {
    background-color: #1d1d1f;
    border: 1px solid #f1f1f1; }
  body.tv-mode .panel-gray {
    background-color: #1d1d1f; }
  body.tv-mode .panel-heading {
    background-color: black;
    color: #f1f1f1;
    border: none; }
    body.tv-mode .panel-heading .timezone {
      display: none; }
  body.tv-mode .map-directions {
    display: none; }

@media (max-width: 767px) {
  .navbar-nav .open .dropdown-menu {
    font-size: 12px; } }

.select2-container .select2-selection--single {
  height: 30px; }

.select2-container--default .select2-selection--single {
  border: 1px solid #ccc; }

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 30px; }

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 30px; }
;